import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Community Contributed Translations`}</h1>
    <p>{`The Polis participation interface automatically detects the browsers preferred language settings, and updates the text it presents accordingly.
These translations live in "strings" files, which can be reviewed here:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/compdemocracy/polis/tree/edge/client-participation/js/strings"
        }}>{`https://github.com/compdemocracy/polis/tree/edge/client-participation/js/strings`}</a></li>
    </ul>
    <h2>{`Adding a translation`}</h2>
    <p>{`New translations can be submitted for a new language by following these steps, and does not require a programmer:`}</p>
    <ul>
      <li parentName="ul">{`Login to (or `}<a parentName="li" {...{
          "href": "https://github.com/signup"
        }}>{`sign up for`}</a>{`) a GitHub account.`}</li>
      <li parentName="ul">{`Create a `}<a parentName="li" {...{
          "href": "https://github.com/compdemocracy/polis/new/dev/client-participation/js/strings"
        }}>{`new strings file`}</a></li>
      <li parentName="ul">{`Look up the country code for the language you're adding (e.g. Google "French language code")`}</li>
      <li parentName="ul">{`Name the new file `}<inlineCode parentName="li">{`<language-code>.js`}</inlineCode>{` (leave out the `}<inlineCode parentName="li">{`<>`}</inlineCode>{` brackets)`}</li>
      <li parentName="ul">{`Copy the code in the canonical `}<a parentName="li" {...{
          "href": "https://github.com/compdemocracy/polis/blob/dev/client-participation/js/strings/en_us.js"
        }}>{`english language strings file`}</a>{` over in to your new strings file.`}</li>
      <li parentName="ul"><strong parentName="li">{`Translate the text in the quotation marks (`}<inlineCode parentName="strong">{`"..."`}</inlineCode>{`), leaving any brackets (`}<inlineCode parentName="strong">{`"... {{ }} ..."`}</inlineCode>{`) or code outside of the quotation marks unaltered`}</strong>{`.`}</li>
      <li parentName="ul">{`(Optional) Down below where it says "Propose new file", you can replace the default description of "Create new file" with something like "Add French translation"; This is helpful, but not strictly necessary.`}</li>
      <li parentName="ul">{`Finally, click the button that says "Propose new file".`}</li>
    </ul>
    <p>{`While it's helpful to do your best to `}<strong parentName="p">{`only edit the text in the string (quotation marks), and leave brackets intact`}</strong>{`, rest assured that we have automated testing of the codebase to catch any mistakes you might make, and will happily fix them up for you.
We'd rather take the time to fix up any technical issues with your submission than to not be able to support a language.`}</p>
    <p>{`If you have any questions about the best interpretation of a particular word or phrase that you are translating, please reach out to us at `}<a parentName="p" {...{
        "href": "mailto:hello@compdemocracy.org"
      }}>{`hello@compdemocracy.org`}</a>{`.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` we do a review of all submissions using Google Translate as a sanity check to prevent bad actors from intentionally injecting bad translations.`}</p>
    <h2>{`Updating translations`}</h2>
    <p>{`If you think that some of the existing translations could be improved, you can edit it as follows:`}</p>
    <ul>
      <li parentName="ul">{`Login to (or `}<a parentName="li" {...{
          "href": "https://github.com/signup"
        }}>{`sign up for`}</a>{`) a GitHub account, as described above.`}</li>
      <li parentName="ul">{`Find the corresponding `}<a parentName="li" {...{
          "href": "https://github.com/compdemocracy/polis/tree/dev/client-participation/js/strings"
        }}>{`strings file`}</a>{`, using the language's language code (as described above).`}</li>
      <li parentName="ul">{`Click on that filename.`}</li>
      <li parentName="ul">{`Click the edit button (the little pen icon in the upper right hand corner of the file view).`}</li>
      <li parentName="ul">{`Make any changes you'd like to propose to the file, following the guidance described above.`}</li>
      <li parentName="ul">{`Add a description of your changes in the provided boxes, and click the "Propose changes" button when done (again, as described above)`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      